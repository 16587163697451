/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * 
 * Below are the WordPress Block Editor Styles so that styles in
 * the editor reflect the gatsby styles
 */
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReedmakerConfig from "./content/reedmaker.yaml"
import { CartProvider } from './src/hooks/useCart';
import "./src/css/style.css";

//export const wrapPageElement = ({ element, props }) => {
//  return <Layout {...props}>{element}</Layout>;
//};

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={ReedmakerConfig.recaptcha.siteKey}>
      <CartProvider>
          {element}
      </CartProvider>
    </GoogleReCaptchaProvider>
  );
};